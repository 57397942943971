html{
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0px !important;
}
.navBarTitle{
  font-family: Puritan;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 17px;
}
input{
  padding:0;
}
.innerBg{
  position: absolute;
  top:151px;
  display:grid;
  width:100%;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 4fr;
  align-items: center;
}

.mobileNav{
  height:50px;
  width:100%;
  top:0px;
  position:absolute;
  z-index: 2;
  display: flex;
}
.underLine{
  font-weight: 600;
  color:#37A000 !important;
  text-decoration-line: underline;
}
.alertBg{
z-index: 2;
  position: absolute;top:0px;
  width: 100%;
 height: 60px;
  display: flex;
  justify-content: center;
  background-color: #F7F7F7;
}
.semiTitle{
  width: 307px;
height: 20px;
left: 487px;
top: 575px;
text-align: center;
font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.01em;

color: #000000;

}
.mainCards{
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.mainCard{
  margin:0.5rem;
  position:relative !important;
  width: 380px ;
height: 380px;

background: #FFFFFF;
border-radius:10px;
box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}
.mainButtonLetter{
  font-family: Neuton;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;
/* identical to box height */

letter-spacing: 0.01em;

color: #37A000;

}
.mainButton{
  position: absolute;
width: 126px;
height: 45px;
left: 127px;
top: 344px;

background: #FFFFFF;
border: 0.45px solid #000000;
}
.mainInfo{
  position: absolute;
width: 313px;
height: 60px;
left: 38px;
top: 260px;

font-family: Puritan;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 20px;
text-align: center;
letter-spacing: 0.01em;

color: #000000;

}
.mainCardSvg{
  position:absolute;
  top:25px;
  width:160px;
  height:160px;
}
.mainCardTitle{
position: absolute;
top:205px;
font-family: 'Neuton', serif;
font-style: normal;
font-weight: 600;
font-size: 29px;
line-height: 37px;
/* identical to box height */

letter-spacing: 0.01em;

color: #000000;
}
.innerMobileSearch{
  display: flex;
  width: 91vw;
  align-items: center;
  height: 43px;
  border-color: black;
  box-shadow: -5px 10px 15px rgba(0, 0, 0, 0.10);
  border-radius: 60px;
}
.innerMobileSearchSmall{
  display: flex;
  width: 15vw;
  align-items: center;
  height: 43px;
  border-color: black;
  box-shadow: -5px 10px 15px rgba(0, 0, 0, 0.10);
  border-radius: 60px;
}
.mobileSearchBarSmall{
  z-index:2;
  background-color: white;
  width: 100%;
  display: grid;
  position:fixed;
  top:0px;
  grid-template-columns:1fr 1.6fr;
  grid-template-rows: 65px;
  height: 65px;
}
.mobileSearchBar{
  z-index:2;
  background-color: white;
  width: 100%;
  display: flex;
  position: absolute;
  top:60px;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-bottom: 0.1px solid #a3a3a3;
}
.normal{
  font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 300;
height:80px;
max-width: 400px;
font-size: 15px;
line-height: 18px;
text-align: center;
 
}
.mainAlert{
  background-color: #F7F7F7;
}
@media (min-width:650px) {
  .fieldBox{
    width:50vw !important;
  }

}
@media (min-width:100px) {
  .card{
    width:90vw !important;
  }



}
@media (min-width:645px) {
  .card{
    width:45vw !important;
  }
}
@media(min-width:920px){
  .card{
    width:27vw !important;
  }
  .fieldBox{
    width: 480px !important;
  }
}
.flexCard{
  position: relative;
  border-radius: 15px;
  background-color:white;
  width:60vw;
  height:60vh;
}
@media (min-width:760px) {
 
 
  .navBarTitle{
    font-size: 22px !important;
  }
    .registerText{
    font-size: 16px !important;
  }
  .modalLoginCard{
    height: 80vh !important;
  }
  .bottomNavbar{
      display: none !important;
  }
  .floatingIcon{
    display: none !important;
}
  .bottomNavbar2{
    display: none !important;
}
  .mobileNav{
  display: none !important;
}
.mobileNavListings{
  display: none !important;
}
.cards{
  width: 100% !important;
  flex-wrap: wrap;
}

.mainListingGrid{
  display:flex;
}

.topImage{
  height: 400px !important;
}
}
@media (max-width:760px) {
  .flexCard{
    position: relative;
    border-radius: 15px;
    background-color:white;
    width:90vw !important;
    height:60vh !important;
  }
  .mainListingGrid{
    display:grid;
    grid-template-columns: 1fr !important;
    grid-template-rows:auto;
  }
  .map{
    display:none;
  }
  .card{
    width: 70vw;
  }
  .cards{
    box-shadow: none !important;
  }
  .background{
      display: none !important;
  }
  .indexNavBar{
  display: none !important;
}

.navBar{
  display: none !important;
}
  .hugeSearch{
      width:80vw !important;
  }
}
.middleLogo{
}
.leftWing{
  display: flex;
  display: flex;
  justify-content: space-evenly;
}
.rightWing{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.background{
  display: flex;
  align-items: center;
  justify-items: center;
  background-image: url(./media/index2.jpg);
  height:70vh;
}
#hugeInput{
  width: 100%;
  margin-left: 28px;
}
#hugeInput::placeholder{
  width: 495px;
height: 20px;
left: 347px;
top: 320px;

font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.01em;

color: #969696;


}
.buttonText2{
  font-family: Segoe UI Symbol;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FFFFFF;
}
.blurryHolder{
  width: 495px;
height: 20px;
left: 347px;
top: 320px;

font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 30px;
letter-spacing: 0.01em;

color: #969696;
}
.box1{
  align-items: center;
  justify-content: flex-start;
  display:flex;
}

.box2{
  justify-content: flex-end;
  align-items: center;
  display:flex;
}
.suggestSearch{
  display: none;
  height: 100px;
  background-color: white;
}
.hugeBox{

}
.hugeSearch{
  display:grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 66px;
  position: static;
  top:200px;
  align-items: center;
  z-index: 4;
  width: 637px;
  height: 66px;
  background: #FFFFFF;
  border-radius: 3px;
}
.hugeSearch:hover{
  border: 2px solid #37A000;
  border-radius: 3px;
}
.indexNavBar{
  align-items: center;
  z-index: 2;
  top:0px;
  min-width:100vw;
  position: fixed;
  display:grid;
  height: 85px;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: 85px;
}
.navBar{
  background-color: white;
  z-index: 2;
  top:0px;
  min-width: 100vw;
  position: fixed;
  display:grid;
  height: 60px;
  grid-template-columns: 1fr 3fr 3fr 3fr;
  grid-template-rows: 60px;
}
.invisible{
  border:0px;
  outline:0px;
}
.mainInput{
  margin-left: 4px;
  width:80%;
  border:0px;

}
.mainInput:focus{
  outline:0px !important;
  border:0px !important;
}
.searchIcon{
  margin-left: 5px;
}
.offButton{
/* Rectangle 45 */
display: flex;
align-items: center;
width: 122.44px;
height: 37.29px;
border: 0.2px solid #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.botones{
  display: flex;
  position:static;
  width: 246px;
  height: 37.29px;
}
.smallButtonText{
  font-family: 'Roboto', sans-serif, medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.025em;
}
.grey1{
  font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.025em;

color: #404040;


}
.white{
  color: #FFFFFF;
}
.buttonClass{
    cursor: pointer;
}
button{
  color:white !important;
  border-color:white !important;
  outline:0px;
  cursor: pointer;
  padding:0px;
  border-width: 0px;
}
button:active{
  outline:0px;
}
.offButton:hover{
  background: #469c189f !important;
}
/* .onButton:hover{
  background: #2863089f !important;
} */
.offButton .smallButtonText{
  color:grey;
}
.onButton{
  align-items: center;
  /* Rectangle 45 */
  width: 122.44px;
  height: 37.29px;
  background: #37A000;
  
  border-radius: 10px;
  display: flex;
  }
.mainSearch{
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  width:30vw;
  margin-right: 10px;
  height:40px;
  background: #FFFFFF;
  border: 0.1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.leftNav{
  padding-left: 5px;
}
.centerNav{
}
.rightNav{
}

.centerY{
  transform: translateY(-50%);
  position: relative;
  top:50%;
}
.centerX{
  transform: translateX(-50%);
  position: relative;
  left:50%;
}
.sendRight{
  margin-left: 5px;
}
.floatingNum{
  position:relative;
  right:11.5px;
  bottom:10px;
  font-size: 12px;
  z-index: 2;
  color:white;
  font-weight: 600;;
}
.rightNav{
  display: flex;
  justify-content: flex-end;
}
.map{
  background-color: gray;
}
.mainListingGrid{
  display:grid;
  grid-template-columns: 1.45fr 1fr;
  grid-template-rows:auto;
}
.cards{
  min-height:60vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.25);

}
.card{
  margin:10px;
  padding-bottom:20px;
  position: relative;
  width: 350px;
  height: 200px;
}
.screener{
  position: relative;
  top: 112px;
  z-index: 4;
  height: 60px !important;
  background-color: rgba(0, 0, 0, 0.48);
}
.locationText{
  z-index:2;
  font-family: 'Roboto', sans-serif, medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.precio{
  z-index:2;
  width: 230.21px;
  height: 25.43px;
  font-family: 'Roboto', sans-serif, medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.045em;
  
  color: #FFFFFF;
  
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.flexEnd{
  display: flex;
  justify-content: flex-end;
}
.flexStart{
  display: flex;
  justify-content: flex-start;
}
.alignCenter{
  display: flex;
  align-items: center;
}
.smallText1{
  margin:5px;
}
.bottomCard{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  height: 44.85px;
  
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.justify{
  justify-content: center;
}

.registerText{
  font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 17px;
letter-spacing: 0.01em;

color: #000000;

}
.registerButton{
  width:100px;
  height:50px;
  background: #FFFFFF;
  border: 0.1px solid #000000;
  border-radius: 30px;
}
.mainHover{
  padding:8px;
  display:none;
  position: absolute;
  top:43px;
  right:0px;
  z-index: 10;
  width: 182px;
  background: #FFFFFF;
  border: 0.2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.profilePicture{
  width:40px;
  height:40px;
  border-radius:50%;
  
}
.selectedBorder{
  border: 4px solid #37A000
}
.profileButton:hover .mainHover{
  display:block;
}
.resultsText{
font-family: 'Roboto', sans-serif, medium;
font-style: normal;
font-weight: 500;
font-size: 22px;
/* identical to box height */
margin:0px !important;
letter-spacing: 0.025em;

color: #000000;

}

.optionBox{
  position:relative;
  z-index: 2;
  width: 100%;
  height:46px;
  background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 5px;
}
.wideText{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
font-weight: bold;
font-size: 17px;
line-height: 20px;
/* identical to box height */


color: rgba(255, 255, 255, 0.9);
}
.wideButton{
  z-index: 1;
  display: flex;
  width: 100%;
  height: 40px;
  background: #37A000;
  border-radius: 5px;
}
.upperFieldBox{
  border-bottom: 0px !important;
  border-radius: 8px 8px 0px 0px !important;
  width: 100%;
}
.smallText2{
  width: 66px;
height: 14px;

font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #000000;

}
.bottomFieldBox{
  border-radius: 0px 0px 8px 8px !important;
}

.normalText::placeholder{
  z-index: 3  ;
  height: 19px;
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

color: #000000;
}
.fieldBox{
  display:flex;
  padding-left: 1rem;
  align-items: center;
  width: 90vw;
height: 57px;

border: 0.8px solid #000000;
box-sizing: border-box;
border-radius: 8px;
transform: rotate(0);
}

.closeIcon{
  position: absolute;
  left:18px;
}
.secondTitle{
  
  font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
color: #1F1F1F;

}
.upperCard2{
  position: absolute;
  top:0px;
  display: flex;
  align-items: center;
  width: 100%;
}
.upperCard{
  width:100%;
  position: absolute;
  top:0px;
  display: flex;
  align-items: center;
  width: 100%;
  height:50px;
  border-bottom: 0.1px solid black;
}
.normalText{

height: 19px;
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

color: #000000;

}
/* BOTTOM */



.middleIcon{
  display: flex;
  justify-content: center;
}
.selected{
  fill:#37A000 !important;
}
.floatingIcon{
  position:fixed;
  z-index: 30;
  bottom:80px;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  border-radius: 30px;
  width: 150px;
  height: 40px;
  border: 1px solid black;
  background-color:white
}
.bottomNavbar2{
  z-index: 5;
  width:100%;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 65px;
  background-color:white;
  border-top: 0.2px solid #484848;
  box-sizing: border-box;
  position: fixed !important;
  bottom:0px !important;
  
  color:white;
  display:grid;
  grid-template-columns:2fr auto 2fr;

}

.bottomNavbar{
  z-index: 10;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  background-color:white;
  border-top: 0.2px solid #484848;
  position: fixed;
  bottom:0px;
  height:65px;
  color:white;
  display:grid;
  grid-template-columns:repeat(5,20vw);

}

.bottomIcon{
  text-align: center;
  position:relative;
}

/* MODAL */

.modal{

  position: fixed;
  width:100vw;
  height:100vh;
  
  z-index:30;}
.modalFade{
  background-color:rgba(0, 0, 0, 0.80);
}
p{
  margin:0px;
}
.centerCard{
  margin-top:28px;
  display: grid;
  grid-template-columns: 1fr;

}
.centerCard2{
  margin-top:28px;
  display: grid;
  grid-template-columns: 1fr;
  padding:20px !important;
}
.mobileLogin{
  display:flex;
  position: absolute;
width: 100%;
height: 604px;
background: #FFFFFF;
border-radius: 20px;
}
.modalLoginCard{
  position: absolute;
width: 533px;
height: 604px;
background: #FFFFFF;
border-radius: 20px;
}
a{text-decoration: none;}

.mobileTitle{
  position: absolute;
  top:1.5rem;
  font-family: 'Roboto', sans-serif,bold;
  font-weight: 600 !important;
  font-size: 36px !important;
  color: #1F1F1F; 
}