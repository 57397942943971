/* CivilTools */
@media (max-width:496px){
  .dashBox{
    min-height:560px !important;
    max-width:380px !important;
  }
}
@media (max-width:760px){
  .buttonBox1{
    width: 80% !important;
  }
  .mainContentTitleWhite{
    width:90vw !important;
  }
  .bigBody{
    width:90vw !important;
  }
}
@media (min-width:760px){
  .buttonBox1{
    width: 30% !important;
  }
  .mainContentTitleWhite{
    width:600px !important;
  }
  .bigBody{
    width:600px !important;
  }
}
.dashBox{
  max-width:700px;
  margin-top:40px;
  min-height:380px;
}
.tableBox2{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 66% 34%;
  width:90vw;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.tableBox{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    width:90vw;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
#root{
    overflow: hidden;
}
.nivelTag{
  position: relative;

background: #FFFFFF;
}
.nivelText{
  padding:2px;
  font-family: BRLNSR;
font-style: normal;
font-weight: normal;
font-size: 12px;
}
.deleteIcon{
  display:none !important;
}
.deleteLine:hover .deleteIcon{
  display:grid !important;
}
.mainLineTextBlack{
  display: flex;
align-items: center;
padding-left: 0.5rem;
font-family: BRLNSR;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 22px;
color:black;
}
.mainLineText{
  padding-left: 0.5rem;
display: flex;
align-items: center;
font-family: BRLNSR;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 22px;
/* identical to box height */


color: #FFFFFF;

}
.table{
}
.green{background: #88C53A;height:50px !important;}
.zsup{
    border-bottom: 1px solid #FFFFFF;
    border-top: 1px solid #FFFFFF;
}
.zsupp{
    border-left: 2px solid #FFFFFF;
}
.mainLine{
    width:100%
}
.textAlign{
    text-align: center;
}
.tableFormat{
    display: grid;
    grid-template-columns: 6fr 1fr 1.5fr 3fr 4fr;
    grid-template-rows: auto;
}
.tableFormat2{
  display: grid;
  grid-template-columns: 11.5fr 4fr;
  grid-template-rows: auto;
}
.dargGrey{
    background: #C4C4C4;
}
.lightGrey{
    background: #DFDFDF;
}
.bottomTitle{
height: 22px;

font-family: Segoe UI Symbol;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 27px;

color: #000000;
}
.bottomTable{
  background: #ffffff;
    border-radius: 0px 0px 5px 5px;
}
.nivelesTitle{
    /* Niveles: 8 */

font-family: Segoe UI Symbol;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 27px;

color: #000000;

}
.dashNavBar{
  position: fixed;
  top:0px;
  background-color: white;
  width:100%;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-template-rows: 60px;
  z-index: 10;
}
.smallSearchGrid{
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-rows: 42px;
  border-bottom: 0.1px solid rgb(53, 53, 53);
  padding-bottom:0.7rem;
}
.smallSearch{
  position: relative;
  margin-left:1rem;
  margin-right:1rem;
  
  width: 70% !important;
height: 42px;

border: 1px solid #000000;
box-sizing: border-box;
border-radius: 30px;
}
.mainTitle{
font-family: adobe-gothic-std, sans-serif;

font-weight: 700;

font-style: normal;
    font-size: 30px;
    line-height: 60px;
    color: #000000;
  }
  
  .template{
  
  }
  .blurryLetter1{
    width: 110.99px;
    height: 36px;
    font-family: Adobe Ming Std;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    color: #000000;
  }
  
  .optionText{
    width: 101px;
    height: 21px;
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
  .flexStart{
      justify-content: flex-start;
  }
  .flexEnd{
    justify-content: flex-end;
}
  .hugeTitle{
font-family: adobe-gothic-std, sans-serif;

font-weight: 700;

font-style: normal;
    font-size: 40px;
    line-height: 80px;
    
    color: #FFFFFF;
    
  }
  .chatBg{
    background: linear-gradient(329.34deg, #05ACE7 -13.64%, #4756C3 80.55%);
  }
  .bigBody{
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
  }
  .modalContainer{
      position: relative;
      width: 100%;
      height: 100%;
  }
  .buttonBox1{
    height: 68px;
    width: 80%;
    background: #88C53A;
    border-radius: 100px;   
    }
  .buttonText1{
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 35px;
    color: #FFFFFF;
  }
  .helpSection{
    position:fixed;
    bottom:30px;
    right:1.5rem;

  }
  .innerHelpSection{
    position: relative;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .alignCenter{
    display: flex;
    width: 100%;
    align-items: center;
  }
  .options{
      padding-top: 15px;
      display: grid;
      column-gap: 1rem;
      justify-content: center;
      grid-template-columns: repeat(5,auto);
      grid-template-rows: auto;
      height:40px;
      padding-left: 20px;
      padding-right: 20px;
  }
  .justify{
    justify-content: center;
  }
  .mainBg{
      display:grid;
      grid-template-columns: 1fr;
      grid-template-rows:50px 50px 180px 120px 50px;
      height:100vh;
      width: 100vw;
  }
  .cardyB{
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35px auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
  .bgColor1{
    background: linear-gradient(360deg, #1A3C78 -28.88%, #000000 100%);
  }
  
  .bgColor2{
  background: linear-gradient(119.58deg, #88C53A 20.83%, #24B3B3 121.94%);
  }
  .bgColor3{
    background: linear-gradient(127.75deg, #151756 22.56%, #0C56AD 86.73%);
  }
  .mainContentBox{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .mainContentTitleWhite{
    font-family: BRLNSR;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    
    color: #FFFFFF;
    
  }
  .mainContentTitle{
    height: 35px;
    font-family: BRLNSR;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    color: #000000;
  }
  .mainContentTextBold{
    width: 93px;
    height: 22px;
    font-family: BRLNSR;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
    
  }
  .innerBox{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 10px;
  }
  .subTotalText{
    font-family: BRLNSR;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }
  
  .regularText{
    width: 39px;
    height: 22px;
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }
  .cardBox{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    width: 461px;
    
  }
  .flexWrap{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 3rem;
  }
  .upperCard{
    height: 157px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 0px 0px;
  }
  .bottomCard{
    
    height: 76px;
    background: #C4C4C4;
    border-radius: 0px 0px 15px 15px;
  }
  .searchBar{ 
    width: 382px;
  height: 48px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  }
  
  .buttonBox2{
    border: 3px solid #88C53A;
  box-sizing: border-box;
  border-radius: 100px;
  padding:4px;
  }
  .buttonBox3{
    border: 0.5px solid black;
  box-sizing: border-box;
  border-radius: 100px;
  padding:5px;
  }