/* SHINE */
.hover14 .figure {
	position: relative;
}
.hover14 .figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.hover14 .figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
  }
  .lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: black;
	margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
  }
  .lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
  }
  .lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
  }
  .lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
  }
  .lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
  }
  .lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
  }
  .lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
  }
  .lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
  }
  @keyframes lds-roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  .pure-material-button-contained {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    min-width: 64px;
    height: 26px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    background-color: rgb(var(--pure-material-primary-rgb, 194, 29, 29));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.pure-material-button-contained::-moz-focus-inner {
    border: none;
}

/* Overlay */
.pure-material-button-contained::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-contained::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 22px; /* Safari */
    height: 22px; /* Safari */
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-contained:hover,
.pure-material-button-contained:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:hover::before {
    opacity: 0.08;
}

.pure-material-button-contained:focus::before {
    opacity: 0.24;
}

.pure-material-button-contained:hover:focus::before {
    opacity: 0.3;
}

/* Active */
.pure-material-button-contained:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
}

/* Disabled */
.pure-material-button-contained:disabled {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
    box-shadow: none;
    cursor: initial;
}

.pure-material-button-contained:disabled::before {
    opacity: 0;
}

.pure-material-button-contained:disabled::after {
    opacity: 0;
}